import { AsyncPipe, NgClass, NgFor, NgIf, NgStyle } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { Router, RouterLink } from '@angular/router';
import { country } from '@features/country/data';
import { user } from '@features/user/data';
import { TranslateModule } from '@ngx-translate/core';
import { getMerchantIdVerification } from '@presentation/kyc/atoms/utils/get-verification-object';
import { toastError, toastSuccess } from '@presentation/shared/toast';
// @ts-ignore
import * as variants from '@taager-experience-shared/variants';
import { LocalizedComponent } from 'app/presentation/base/localized.component';
import { finalize, forkJoin, lastValueFrom, map, Observable, of, Subscription } from 'rxjs';
import { CartProductAvailabilityModel } from 'src/app/core/domain/cart.model';
import { MerchantStore } from 'src/app/core/domain/merchant-store.model';
import { ProductAvailability } from 'src/app/core/domain/variant-group.model';
import { LogGTagEventUseCase } from 'src/app/core/usecases/analytics/log-gtag-event.usecase';
import { LogMixpanelEventUseCase } from 'src/app/core/usecases/analytics/log-mixpanel-event.usecase';
import { UpdateProductInCartUseCase } from 'src/app/core/usecases/cart/update-product-in-cart.usecase';
import { GetCountryVATByIsoCode3UseCase } from 'src/app/core/usecases/get-country-vat.usecase';
import { GetFeatureAttributeUsecase } from 'src/app/core/usecases/get-feature-attribute.usecase';
import { GetFeatureFlagUsecase } from 'src/app/core/usecases/get-feature-flag.usecase';
import { GetMarketClosingReasonUseCase } from 'src/app/core/usecases/market-availability/get-closing-reason.usecase';
import { IsFeatureEnabledUseCase } from 'src/app/core/usecases/market-availability/is-feature-enabled.usecase';
import { AddProductToStoreUseCase } from 'src/app/core/usecases/merchant-store/add-product-to-store.usecase';
import { GetUserStoresUseCase } from 'src/app/core/usecases/merchant-store/get-user-stores-usecase';
import { GetProductVATUseCase } from 'src/app/core/usecases/products/get-product-vat.usecase';
import { GetUserCountryUseCase } from 'src/app/core/usecases/user-location/get-user-country.usecase';
import { CheckUserFeatureExistsUseCase } from 'src/app/core/usecases/user/check-user-feature-exists.usecase';
import { CatalogService } from 'src/app/presentation/shared/services/catalog.service';
import { MultitenancyService } from 'src/app/presentation/shared/services/multitenancy.service';
import { QuantityDiscountBannerComponent } from '../../cart/quantity-discount-banner/quantity-discount-banner.component';
import { LoaderComponent } from '../../shared/components/loader/loader.component';
import { OrderComponent } from '../../shared/components/order/order.component';
import { SelectedCountryTooltipComponent } from '../../shared/components/selected-country-tooltip/selected-country-tooltip.component';
import {
  FEATURE_FLAGS,
  ORDER_STATUSES,
  STOCK_AVAILABILITY_USER_FEATURE,
} from '../../shared/constants';
import {
  FEATURE_ENABLED_FOR_EVEN_IDS,
  FEATURE_ENABLED_FOR_EVERYONE,
  FEATURE_ENABLED_FOR_ODD_IDS,
} from '../../shared/constants/feature-attribute-states';
import {
  DISCOUNTED_AS_SECOND_PRODUCT,
  PRICE_RANGE_ATTRIBUTE,
  QUANTITY_DISCOUNT,
  STOCK_AVAILABILITY_FEATURE,
  STOCK_DISTRIBUTION,
  VAT_ON_DISCOUNTED_PRICE,
  WEB_STORES,
} from '../../shared/constants/feature-flags';
import { CATALOG_BANNER_KEY } from '../../shared/constants/quantity-discount';
import { HoverStyleDirective } from '../../shared/directives/hover-style.directive';
import { Country } from '../../shared/interfaces/countries';
import { CurrencyTranslatePipe } from '../../shared/pipes/currency-translate.pipe';
import { ProductAvailabilityPipe } from '../../shared/pipes/product-availability.pipe';
import { FlashSalesService } from '../../shared/services/flash-sales.service';
import { LocalStorageService } from '../../shared/services/local-storage.service';
import { ProductService } from '../../shared/services/product.service';
import { ResponsiveService } from '../../shared/services/responsive.service';
import { featureAttributeAssign } from '../../shared/utilities/feature-attribute-assign.utility';
import { getSizedImage, ImageSize } from '../../shared/utilities/get-sized-image.utility';

interface CatalogedProduct {
  Category: string;
  customPrice: number;
  price: number;
  prodID: string;
  productAvailability: string;
  productName: string;
  productPicture: string;
  productPrice: number;
  productProfit: number;
  _id: string;

  productIsSelected: boolean;
  priceEditable: boolean;
  productLink: string;
  productAvailabilityColor: string;
  productAvailabilityText: string;
  merchantSelectedPrice: string;

  $vat: Observable<number>;
  $netProfit: Observable<number>;

  productOriginalProfit: any;
  productSaleOrOrignalPrice: any;

  discountedAsSecondInPlacement: {
    enabled: boolean;
    discountedPrice: number;
    merchantProfit: number;
  };
  addedToStore?: boolean;
  availabilityInfo: CartProductAvailabilityModel;
  remainingHours?: number;
  remainingDays?: number;
  stockIsExpired?: boolean;
  stockExpiryType: 'time' | 'quantity';
}

@Component({
  selector: 'app-catalog',
  templateUrl: './catalog.component.html',
  styleUrls: ['./catalog.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    SelectedCountryTooltipComponent,
    LoaderComponent,
    MatCheckboxModule,
    FormsModule,
    HoverStyleDirective,
    NgFor,
    RouterLink,
    NgStyle,
    NgClass,
    OrderComponent,
    AsyncPipe,
    TranslateModule,
    ProductAvailabilityPipe,
    CurrencyTranslatePipe,
    QuantityDiscountBannerComponent,
    MatProgressBarModule,
  ],
})
export class CatalogComponent extends LocalizedComponent implements OnInit {
  loading = false;

  products: CatalogedProduct[] = [];

  selectAll = false;

  isOrderNow = false;

  selectedProduct: any;

  favChangedSub: Subscription;

  currency: string;

  selectedCountry: Country;

  public shouldShowVAT = false;

  public pageLoading = false;

  public shouldShowDiscountAsSecondProduct = false;

  public showDiscountAsSecondProductMessage = false;

  public userHasStockAvailability: boolean;

  public isResizeImageEnabled = false;

  public isPriceRangeEnabled = false;

  public productAvailabilityEnum = ProductAvailability;

  public productIsAvailableToSellerAndPreOrderable: boolean;

  public userHasStore = false;

  public storeId: string;

  public storeProvider: string;

  public isStoresEnabled = false;

  public assetsPath = 'assets/img/';

  public shouldShowQuantityDiscountBanner = false;

  public bannerEnabled = true;

  private _logMixpanelEventUseCase = inject(LogMixpanelEventUseCase);

  private _updateProductInCartUseCase = inject(UpdateProductInCartUseCase);

  public selectedTab: 'catalog' | 'locked' = 'catalog';

  public tabOptions = [
    { title: 'CATALOG_PAGE.CATALOG_TAB_TITLE', isActive: true },
    { title: 'CATALOG_PAGE.LOCKED_TITLE', isActive: false },
  ];

  public shouldShowStockDistribution = false;

  public isDuplicateAccount = false;

  isMarketClosed = false;

  closingMessage: string;

  constructor(
    private catalogService: CatalogService,
    private router: Router,
    private _getCountryVATByIsoCode3UseCase: GetCountryVATByIsoCode3UseCase,
    private _getProductVATUseCase: GetProductVATUseCase,
    private _logGTagEventUseCase: LogGTagEventUseCase,
    private _getUserCountryUseCase: GetUserCountryUseCase,
    private _responsiveService: ResponsiveService,
    private _localStorageService: LocalStorageService,
    private _flashSalesService: FlashSalesService,
    private _addProductToStoreUseCase: AddProductToStoreUseCase,
    private _getFeatureFlagUsecase: GetFeatureFlagUsecase,
    private _getFeatureAttributeUsecase: GetFeatureAttributeUsecase,
    private _getUserStoresUseCase: GetUserStoresUseCase,
    private _multitenancyService: MultitenancyService,
    private _productService: ProductService,
    private _checkUserFeatureExistsUseCase: CheckUserFeatureExistsUseCase,
    private _isFeatureEnabledUseCase: IsFeatureEnabledUseCase,
    public _getMarketClosingReasonUseCase: GetMarketClosingReasonUseCase,
  ) {
    super();
  }

  ngOnInit(): void {
    this._getFeatureAttributeUsecase.execute(PRICE_RANGE_ATTRIBUTE).subscribe({
      next: (flag) => {
        flag = JSON.parse(flag);
        const status = flag?.status.toLowerCase();
        if (
          flag.country.includes(country.code) &&
          ((status === FEATURE_ENABLED_FOR_ODD_IDS.toLowerCase() && user.id % 2 !== 0) ||
            (status === FEATURE_ENABLED_FOR_EVEN_IDS.toLowerCase() && user.id % 2 === 0) ||
            status === FEATURE_ENABLED_FOR_EVERYONE.toLowerCase() ||
            flag.taagerIds.includes(user.id))
        ) {
          this.isPriceRangeEnabled = true;
        } else {
          this.isPriceRangeEnabled = false;
        }
      },
    });
    this.loadProducts();
    this._getFeatureFlagUsecase.execute(DISCOUNTED_AS_SECOND_PRODUCT).subscribe({
      next: (flag) => {
        this.shouldShowDiscountAsSecondProduct = flag;
      },
    });
    this.currency = this._multitenancyService.getCurrentCountry().currency.arabicName;
    this.checkForStockAvailabilityEligibility();
    this._checkStoresEnabled();
    this._checkQuantityDiscountEnabled();
    this.bannerDisplayCheck();
    this.isStockDistributionEnabled();
    this._isFeatureEnabledUseCase.execute('orders').subscribe((res) => {
      this.isMarketClosed = !res;
    });
    this._getMarketClosureMessage();
    this.checkMerchantIdVerification();
  }

  protected checkMerchantIdVerification(): void {
    getMerchantIdVerification(
      this._getFeatureFlagUsecase,
      this._checkUserFeatureExistsUseCase,
    ).then((response) => {
      this.isDuplicateAccount = response.isDuplicate;
    });
  }

  private _getMarketClosureMessage(): void {
    this._getMarketClosingReasonUseCase.execute('orders').subscribe((res) => {
      if (res) {
        this.closingMessage = res;
      }
    });
  }

  public isStockDistributionEnabled(): void {
    this._getFeatureAttributeUsecase.execute(STOCK_DISTRIBUTION).subscribe((attribute) => {
      const taagerId = user.id;
      this.shouldShowStockDistribution = featureAttributeAssign(attribute, Number(taagerId));
    });
  }

  private _checkQuantityDiscountEnabled(): void {
    this._getFeatureAttributeUsecase.execute(QUANTITY_DISCOUNT).subscribe({
      next: (flag) => {
        this.shouldShowQuantityDiscountBanner = featureAttributeAssign(flag, user.id, country.code);
      },
    });
  }

  checkForStockAvailabilityEligibility(): void {
    this._getFeatureFlagUsecase.execute(STOCK_AVAILABILITY_FEATURE).subscribe({
      next: (flag) => {
        const selectedCountry = country.code.toLocaleLowerCase();
        this.userHasStockAvailability =
          flag &&
          this._checkUserFeatureExistsUseCase.execute(
            `${STOCK_AVAILABILITY_USER_FEATURE}${selectedCountry}`,
          );
      },
    });
  }

  getProductsForCategory(): void {
    this.loading = true;
    this.products = [];
    const filters: { [attribute: string]: any } = {
      pageSize: 50,
      page: 1,
      sortBy: 'introducedAt',
      merchantLockedOnly: true,
    };
    this._productService
      .getProductsForCategory(filters)
      .pipe(
        finalize(() => {
          this.loading = false;
        }),
      )
      .subscribe({
        next: (res: any) => {
          res.results.forEach((item: any) => {
            this.products.push(item.primaryVariant);
          });
          this.calculateStockNumberOfDays();
        },
      });
  }

  loadProducts(): void {
    this.products = [];
    this.loading = true;
    this._getFeatureFlagUsecase.execute(FEATURE_FLAGS.RESIZE_IMAGES).subscribe({
      next: (flag) => {
        if (flag) {
          this._getFeatureAttributeUsecase
            .execute(FEATURE_FLAGS.ALLOWED_IDS_FOR_RESIZE_IMAGES)
            .subscribe({
              next: (ids) => {
                ids = JSON.parse(ids) || [];
                this.isResizeImageEnabled = ids?.length === 0 || ids.includes(user.id);
              },
            });
        }
      },
    });
    let vatOnDiscountedPrice: any;
    this._getFeatureAttributeUsecase.execute(VAT_ON_DISCOUNTED_PRICE).subscribe({
      next: (attribute) => {
        vatOnDiscountedPrice = featureAttributeAssign(attribute, user.id);
      },
    });
    forkJoin({
      shouldShowVAT: this._getCountryVATByIsoCode3UseCase
        .execute(country.code)
        .pipe(map((amount) => amount > 0)),
      catalogData: this.catalogService.getCatalogedProducts(this.selectedTab === 'locked').pipe(
        finalize(() => {
          this.pageLoading = false;
        }),
      ),
    }).subscribe({
      next: ({ shouldShowVAT, catalogData }) => {
        catalogData = this._flashSalesService.checkAllSaleStartAndExpiry(catalogData);
        this.shouldShowVAT = vatOnDiscountedPrice ? false : shouldShowVAT;
        this.products = catalogData.map((product: any) => {
          const productColorHex = product.attributes.filter(
            (attribute: any) => attribute.type === 'color',
          )[0]?.value;
          let affectedByPriceChangeObj = {} as {
            merchantSelectedPrice: number | string;
            productProfit: number | string;
          };
          if (this.isResizeImageEnabled) {
            product.productPicture = getSizedImage(product.productPicture, ImageSize.small);
          }
          this.productIsAvailableToSellerAndPreOrderable =
            product.isAvailableToSeller &&
            product.productAvailability === ProductAvailability.available_for_preorder;
          product.productOriginalProfit = product.productProfit;
          const productProfitOriginalOrSale = product.sale?.productProfit
            ? product.sale.productProfit
            : product.productProfit;
          if (product.sale?.productPrice) {
            product.price = product.sale.productPrice;
            product.productSaleOrOrignalPrice = product.price;
          } else {
            product.productSaleOrOrignalPrice = product.productPrice;
          }

          if (product.price === product.productSaleOrOrignalPrice) {
            affectedByPriceChangeObj = {
              merchantSelectedPrice: product.customPrice,
              productProfit:
                product.customPrice > product.price
                  ? product.customPrice -
                    product.productSaleOrOrignalPrice +
                    productProfitOriginalOrSale
                  : productProfitOriginalOrSale,
            };
          } else {
            affectedByPriceChangeObj = {
              merchantSelectedPrice: '-',
              productProfit: '-',
            };
          }
          const getVatObservable = this._getProductVATUseCase.execute({
            countryIsoCode3: country.code,
            productId: product.prodID,
            productSellingPrice: product.customPrice,
            quantity: 1,
            isOnSale: product.sale,
          });
          return {
            ...product,
            priceEditable: false,
            productIsSelected: false,
            productLink: this.router.serializeUrl(
              this.router.createUrlTree(['/product-details', product._id], {
                queryParams: { productName: product.productName },
              }),
            ),
            productAvailabilityColor: this.getColor(product.productAvailability),
            productAvailabilityText: product.productAvailability,
            ...affectedByPriceChangeObj,
            productColorHex,
            productColor: productColorHex && variants.getColorByHexCode(productColorHex).arabicName,
            productSize: product.attributes.filter((attribute: any) => attribute.type === 'size')[0]
              ?.value,
            $vat: this.shouldShowVAT
              ? getVatObservable.pipe(map((vatAmount) => Math.round(vatAmount * 100) / 100))
              : of(0),
            $netProfit: this.shouldShowVAT
              ? getVatObservable.pipe(
                  map((vatAmount) =>
                    typeof affectedByPriceChangeObj.productProfit === 'number'
                      ? Math.round((affectedByPriceChangeObj.productProfit - vatAmount) * 100) / 100
                      : 0,
                  ),
                )
              : of(productProfitOriginalOrSale),
          };
        });
        if (this.products.some((x) => x.discountedAsSecondInPlacement?.enabled)) {
          this.showDiscountAsSecondProductMessage = true;
        } else {
          this.shouldShowDiscountAsSecondProduct = false;
        }
        this.loading = false;
      },
      error: (err) => {
        this.loading = false;
      },
    });
  }

  public calculateStockNumberOfDays(): void {
    if (this.products.length > 0 && this.shouldShowStockDistribution) {
      const currentDate = new Date();
      this.products.forEach((product) => {
        if (product.availabilityInfo?.stockInfo?.expiresAt) {
          const expiryDate = new Date(product.availabilityInfo?.stockInfo?.expiresAt);
          if (expiryDate > currentDate) {
            product.remainingDays = Math.floor(
              (currentDate.getTime() - expiryDate.getTime()) / (1000 * 60 * 60 * 24),
            );
            product.remainingDays = Math.abs(product.remainingDays);
            if (product.remainingDays < 2) {
              const currentTime = currentDate.getTime();
              const expiryTime = expiryDate.getTime();
              const remainingTime = expiryTime - currentTime;
              if (remainingTime > 3600000) {
                const hoursDifference = remainingTime / (3600 * 1000);
                product.remainingHours = Math.floor(hoursDifference);
              } else {
                product.remainingHours = 1;
              }
            }
          } else {
            product.stockIsExpired = true;
            product.stockExpiryType = 'time';
            product.remainingDays = undefined;
            product.remainingHours = undefined;
          }
        }
        this.checkStockQuantityExpiry(product);
      });
    }
  }

  public checkStockQuantityExpiry(product: any): void {
    if (product.availabilityInfo?.stockInfo?.remainingStock === 0) {
      product.stockIsExpired = true;
      product.stockExpiryType = 'quantity';
    }
  }

  getColor(productAvailability: ProductAvailability): string {
    switch (productAvailability) {
      case ProductAvailability.not_available:
        return '#ff4966';
      case ProductAvailability.available_with_low_qty:
        return '#ffae0c';
      case ProductAvailability.available_for_preorder:
      case ProductAvailability.available:
      case ProductAvailability.available_with_high_qty:
      default:
        return '#3dbb54';
    }
  }

  getProductAvailibilityText(productAvailability: ProductAvailability): string {
    switch (productAvailability) {
      case ProductAvailability.not_available:
        return 'غير متوفر';
      case ProductAvailability.available_with_low_qty:
        return 'متوفر بكمية محدودة';
      case ProductAvailability.available:
        return 'متوفر';
      case ProductAvailability.available_with_high_qty:
        return 'متوفر بكمية كبيرة';
      case ProductAvailability.available_for_preorder:
        return 'متوفر للطلب المسبق';
      default:
        return '';
    }
  }

  onToggleSelectAll(): void {
    if (this.selectAll) {
      this.products.forEach((product) => {
        product.productIsSelected = true;
      });
    } else {
      this.products.forEach((product) => {
        product.productIsSelected = false;
      });
    }
  }

  onToggleProductSelection(): void {
    this.selectAll = this.products.every((product) => product.productIsSelected);
  }

  onAddToCart(product: any): void {
    this._updateProductInCartUseCase
      .execute({
        id: product._id,
        desiredPrice: product.customPrice,
        quantity: 1,
      })
      .subscribe({
        next: () => {
          toastSuccess(this.trans('PRODUCTS_PAGE.PRODUCT_CARD.ADDED_TO_CART'));
        },
        error: (err) => {
          toastError(this.trans('ERRORS.GENERIC_ERROR_MESSAGE'));
        },
      });
  }

  onAddToStore(product: any): void {
    const merchantStore: MerchantStore = {
      storeId: this.storeId,
      provider: this.storeProvider,
    };
    this._logMixpanelEventUseCase.execute({
      eventName: 'add_to_store_clicked',
      payload: {
        ...product,
        productSourcePage: 'catalog page',
        store: this.storeProvider,
      },
    });
    this._addProductToStoreUseCase.execute({ merchantStore, productId: product.prodID }).subscribe({
      next: () => {
        this._logMixpanelEventUseCase.execute({
          eventName: 'add_to_store_success',
          payload: {
            ...product,
            productSourcePage: 'catalog page',
            store: this.storeProvider,
          },
        });
        product.addedToStore = true;
        toastSuccess(this.storeProvider, this.trans('STORES.ADDED_SUCCESSFULLY'));
      },
      error: (err) => {
        this._logMixpanelEventUseCase.execute({
          eventName: 'add_to_store_fail',
          payload: {
            ...product,
            productSourcePage: 'catalog page',
            errorMessage: err.error.description,
            store: this.storeProvider,
          },
        });
        toastError(err.error.description);
      },
    });
  }

  onOrderNow(product: any): void {
    this.selectedProduct = [{ ...product }];
    this.selectedProduct[0].quantity = 1;
    this.selectedProduct[0].productProfit = product.productProfit;
    this.selectedProduct[0].productPrice = product.customPrice;
    this.selectedProduct[0].id = product._id;
    this.selectedProduct[0].pid = product.prodID;
    this.isOrderNow = true;
  }

  reload(): void {
    this.isOrderNow = false;
    this.loadProducts();
  }

  orderSubmitted(event: any): void {
    if (event.status) {
      toastSuccess(this.trans('CATALOG_PAGE.SUCCESS.ORDER_COMPLETION'));
      this._logEventToAnalytics(event.orderID, event.orderQuantity);
    } else {
      const errorToDisplay =
        ORDER_STATUSES.ORDER_ERRORS.find((error) => event.errorMessage.includes(error.description))
          ?.translationKey || 'ERRORS.GENERIC_ERROR_MESSAGE';
      toastError(this.trans(errorToDisplay));
    }
    this.reload();
  }

  /**
   *
   * @param orderNumber
   *
   */
  private async _logEventToAnalytics(orderNumber: string, quantity: number): Promise<void> {
    const { prodID, price, merchantSelectedPrice, productProfit, categoryId } =
      this.selectedProduct[0];
    const merchantProfit = productProfit;
    const eventName = 'Catalog_page_order_now';
    const payload = {
      'Taager ID': user.id,
      'Merchant order number': orderNumber,
      Platform: `Web-${this._responsiveService.returnDeviceCategory()}`,
      'User location': await lastValueFrom(this._getUserCountryUseCase.execute()),
      Product: prodID,
      categoryId,
      'Taager Selling Price': price * quantity,
      'Merchant Selling Price': merchantSelectedPrice * quantity,
      'Merchant Profit': merchantProfit * quantity,
      Quantity: quantity,
    };
    this._logGTagEventUseCase.execute({ eventName, payload });
  }

  onEditPrice(product: any): void {
    product.priceEditable = true;
  }

  onConfirmEdit(product: any): void {
    if (product.merchantSelectedPrice < product.productSaleOrOrignalPrice) {
      toastError(
        `${this.trans('CATALOG_PAGE.ERROR.MINIMUM_PRICE')}${product.productSaleOrOrignalPrice}`,
      );
      // check new custom price is different than the old one only incase of no price change
    } else if (
      product.price === product.productSaleOrOrignalPrice &&
      product.merchantSelectedPrice === product.customPrice
    ) {
      toastError(this.trans('CATALOG_PAGE.ERROR.NEW_PRICE_REQUIRED'));
    } else {
      this.updateFavoriteProductCustomPrice(product);
      product.priceEditable = false;
    }
  }

  updateFavoriteProductCustomPrice(product: CatalogedProduct): void {
    this.loading = true;
    if (product.price === product.productSaleOrOrignalPrice) {
      this.catalogService.updateCustomPrice(product._id, product.merchantSelectedPrice).subscribe({
        next: () => {
          this.reload();
        },
        error: (err) => {
          this.loading = false;
          toastError(this.trans('ERRORS.GENERIC_ERROR_MESSAGE'));
        },
      });
    } else {
      new Promise<void>((resolve, reject) => {
        this.catalogService.uncatalogProduct(product._id).subscribe({
          next: () => {
            this.catalogService.catalogProduct(product._id).subscribe({
              next: () => {
                this.catalogService
                  .updateCustomPrice(product._id, product.merchantSelectedPrice)
                  .subscribe({
                    next: () => {
                      this.reload();
                    },
                    error: (err) => reject(),
                  });
              },
              error: (err) => reject(),
            });
          },
          error: (err) => reject(),
        });
      })
        .then()
        .catch((err) => {
          this.loading = false;
          toastError(this.trans('ERRORS.GENERIC_ERROR_MESSAGE'));
        });
    }
  }

  onCancelEdit(product: any): void {
    product.priceEditable = false;
    if (product.price === product.productSaleOrOrignalPrice) {
      product.merchantSelectedPrice = product.customPrice;
    } else {
      product.merchantSelectedPrice = '-';
    }
  }

  onDeleteProduct(product: any): void {
    this.catalogService.uncatalogProduct(product._id).subscribe({
      next: () => {
        toastSuccess(this.trans('CATALOG_PAGE.SUCCESS.PRODUCT_REMOVED'));
        this.reload();
      },
      error: (err) => {
        toastError(this.trans('ERRORS.GENERIC_ERROR_MESSAGE'));
      },
    });
  }

  onDeleteSelectedProducts(): void {
    const deletedProductsSubscriptions = this.products
      .filter((product) => product.productIsSelected)
      .map((product) => this.catalogService.uncatalogProduct(product._id));
    forkJoin(deletedProductsSubscriptions).subscribe({
      next: () => {
        this.reload();
        if (deletedProductsSubscriptions.length === 1) {
          toastSuccess(this.trans('CATALOG_PAGE.SUCCESS.PRODUCT_REMOVED'));
        } else {
          toastSuccess(this.trans('CATALOG_PAGE.SUCCESS.PRODUCTS_REMOVED'));
        }
      },
      error: (err) => {
        toastError(this.trans('ERRORS.GENERIC_ERROR_MESSAGE'));
      },
    });
  }

  private _checkStoresEnabled(): void {
    this._getFeatureFlagUsecase.execute(WEB_STORES).subscribe({
      next: (flag) => {
        if (flag) {
          this.isStoresEnabled = true;
          this._fetchUserStores();
        }
      },
    });
  }

  private _fetchUserStores(): void {
    this._getUserStoresUseCase.execute().subscribe({
      next: (stores) => {
        const selectedCountry = country.code;
        const storePerMarket = stores.filter((store) => store.market === selectedCountry);
        if (storePerMarket.length > 0) {
          this.userHasStore = true;
          const { storeId, provider } = storePerMarket[0];
          this.storeId = storeId;
          this.storeProvider = provider;
        }
      },
    });
  }

  public bannerDisplayCheck(): void {
    const key = this._localStorageService.getStorage(CATALOG_BANNER_KEY);
    if (key === false) {
      this.bannerEnabled = key;
    }
  }

  toggleActive(tab: any): void {
    if (tab.title === 'CATALOG_PAGE.CATALOG_TAB_TITLE') {
      this.selectedTab = 'catalog';
      this.tabOptions[0].isActive = true;
      this.tabOptions[1].isActive = false;
      this.loadProducts();
    } else {
      this.selectedTab = 'locked';
      this.tabOptions[0].isActive = false;
      this.tabOptions[1].isActive = true;
      this.getProductsForCategory();
    }
  }
}
